import React from "react";

export const prefix = (narrowLeft, color, customStyles) => {
  let style;
  if (narrowLeft) {
    style = {
      borderBottom: `6rem solid ${color}`,
      borderLeft: `100vw solid transparent`,
      ...customStyles
    };
  } else if (!narrowLeft) {
    style = {
      borderBottom: `6rem solid ${color}`,
      borderRight: `100vw solid transparent`,
      ...customStyles
    };
  } else {
    console.warn("narrowLeft not specified");
  }

  return <div style={style}></div>;
};

export const suffix = (narrowLeft, color) => {
  let style;
  if (narrowLeft) {
    style = {
      borderBottom: `6rem solid transparent`,
      borderRight: `100vw solid ${color}`,
    };
  } else if (!narrowLeft) {
    style = {
      borderBottom: `6rem solid transparent`,
      borderLeft: `100vw solid ${color}`,
    };
  } else {
    console.warn("narrowLeft not specified");
  }

  return <div style={style}></div>;
};
