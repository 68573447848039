import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import "fontsource-raleway/700.css";
import "fontsource-raleway/200.css";

import Header from "./header";
import Footer from "./footer";
import CookieConscent from "./cookie-conscent";

// styles
const mainStyles = {
  background: "transparent",
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main style={mainStyles}>{children}</main>
      <Footer />
      <CookieConscent />
    </>
  );
};

export default Layout;
