import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import headerCss from "./header.module.css";

import LogoSvg from "../images/logo.svg";
import CallSvg from "../images/icons/call.svg";

// style
const pictureStyles = {
  position: "absolute",
  height: "35rem",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: -1,
  filter: "brightness(0.5)",
};

const iconStyles = {
  fill: "whitesmoke",
  height: "1.2rem",
  width: "1.2rem",
};

const paragraphStyles = {
  fontSize: "1em",
  letterSpacing: " .1em",
  lineHeight: 2,
  textTransform: "uppercase",
};

const measeureStyles = {
  paddingLeft: "1rem",
  margin: 0,
  fontWeight: 700,
};

const sloganStyles = {
  ...paragraphStyles,
  marginTop: "1rem",
  fontWeight: 200,
};

// markup
const Header = (props) => {
  const { showDescription = true, showDiscount = false } = props;

  const data = useStaticQuery(graphql`
    query HeaderImage {
      file(relativePath: { eq: "main/main.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  
  return (
    <header className={headerCss.header}>
      <Img
        style={pictureStyles}
        fluid={data.file.childImageSharp.fluid}
        alt="Conference room with blinds covering the windows."
      />
      <section className={headerCss.section}>
        <Link to="/">
          <img src={LogoSvg} alt="logo rolety lider" />
        </Link>
        <a className={headerCss.call} href="tel:+48502647320">
          <img style={iconStyles} src={CallSvg} alt="call icon" />
          <p style={{ ...paragraphStyles, ...measeureStyles }}>
            bezpłatny pomiar
          </p>
        </a>

        {showDescription ? (
          <p
            className={headerCss.centerText}
            style={{ ...paragraphStyles, ...sloganStyles }}
          >
            Rolety, plisy, żaluzje, moskitiery na wymiar
          </p>
        ) : null}
      </section>
      {showDiscount ? <section>todo special offer</section> : null}
    </header>
  );
};

export default Header;
