import React from "react";

import { prefix } from "./offer-section.utils";

import footerCss from "./footer.module.css";

const copyrightStyles = {
  textAlign: "center",
  padding: "1em 0",
  background: "#20222d",
  width: "100%",
};

const hrefStyles = {
  color: "whitesmoke",
};

const footerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#2e3141",
};

const Footer = () => (
  <>
    {prefix(true, "#2e3141", { marginTop: "-6.05rem" })}
    <footer style={footerStyles}>
      <section className={footerCss.wrapper}>
        <article>
          <h3>Kontakt</h3>
          <p>
            Email:
            <a style={hrefStyles} href="mailto:kontakt.fhlider@gmail.com">
              kontakt.fhlider@gmail.com
            </a>
          </p>
          <p>
            Telefon:{" "}
            <a style={hrefStyles} href="tel:+48502647320">
              +48 502 647 320
            </a>
          </p>
          <p>Krzysztof Smoleń</p>
        </article>
        <article>
          <h3>Dane firmy:</h3>
          <p>Rolety Lider</p>
          <p>NIP: 9441400101</p>
          <p>REGON: 351363647</p>
        </article>
        <article>
          <h3>O Nas:</h3>
          <p>
            Rolety Lider to firma z ponad 21 letnim doświadczeniem.
            Współpracujemy tylko ze sprawdzonymi na przestrzeni lat
            producentami.
          </p>
        </article>
      </section>
      <p style={copyrightStyles}>
        © {new Date().getFullYear() || "2020"} Copyright Rolety Lider
      </p>
    </footer>
  </>
);

export default Footer;
