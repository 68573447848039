import React, { Component, useState } from "react";

const cookiesStyle = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  whiteSpace: "break-spaces",
  padding: "1rem",
  justifyContent: "space-between",
  display: "flex",
  background: "rgba(0, 139, 139, .6)",
};

class CookieConscent extends Component {
  text;

  state = {
    show: false
  }

  componentDidMount() {
    let lang = "pl-PL";
    lang = window.navigator.language;
    if (lang === "pl-PL") {
      this.text =
        "Nasza strona ustawia TYLKO anonimowe cookies. BEZ reklam, BEZ śledzenia. Respektujemy zasadę 'Do Not Track'";
    } else {
      this.text =
        "Our website uses ONLY anonymised cookies for traffic analytics.\nNO ads, NO abusing tracking. We respect 'Do Not Track' request.";
    }

    const hasConscent = !!window.localStorage.getItem("cookie_conscent");

    setTimeout(() => {
      if (!hasConscent) {
        this.setState({ show: true });
      }
    }, 1000);
  }

  conscent() {
    window.localStorage.setItem("cookie_conscent", true);
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        {this.state.show ? (
          <div style={cookiesStyle}>
            {this.text}
            <button onClick={() => this.conscent()}>OK</button>
          </div>
        ) : null}
      </>
    );
  }
}

export default CookieConscent;
